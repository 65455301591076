<template>
<el-dialog :visible.sync="show" :title="$t('tools.component_EngineConfig_dialog_title')" @close="onClose" destroy-on-close class="none-padding-body" width="780px" top="20px">
    <div class="alert-warn-block text-center">{{$t('tools.component_EngineConfig_dialog_tips_1', {size: userInfo.commonlyEngineLimit.maxEngineCount})}}</div>
    <div class="engine-wrapper">
        <template v-for="domain in engineList">
            <div class="domian-name" :key="domain.key">{{domain.name}}</div>
            <div class="engine-list" :key="`engine-${domain.key}`">
                <template v-for="engine in domain.engines">
                    <div class="item" :class="{'active': engine.commonly}" :key="engine.key" @click="selectEngine(engine)">
                        <svg-icon :name="`ie-${engine.icon}`" className="fonts-18"></svg-icon>
                        <span class="margin-l-5 fonts-12">{{ engine.name }}</span>
                    </div>
                </template>
            </div>
        </template>
    </div>
    <div slot="footer" style="text-align: center;">
        <el-button plain round size="small" @click="show = false">{{$t('tools.component_EngineConfig_dialog_btn_1')}}</el-button>
        <el-button round type="primary" size="small" :loading="loading" @click="submitBtnEvent">{{$t('tools.component_EngineConfig_dialog_btn_2')}}</el-button>
    </div>
</el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { projectApi, myEngineApi } from '@/utils/api';
export default {
    data() {
        return {
            show: false,
            loading: false,
            engineList: [],
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
        }),
    },
    methods: {
        setShow() {
            this.show = true;
            this.getEngineList();
        },
        getEngineList() {
            let url = myEngineApi.queryTranslatorConfigEngines;
            this.$ajax.get(url).then(res => {
                if (res.status === 200) {
                    this.engineList = res.data;
                }
            });
        },
        onClose() {
            this.engineList = [];
        },
        selectEngine(engine) {
            if (!engine.commonly) {
                let commonlyEngines = [];
                this.engineList.map(domain => {
                    domain.engines.map(item => {
                        if(item.commonly) {
                            commonlyEngines.push(item);
                        }
                    });
                });
                if (commonlyEngines.length >= this.userInfo.commonlyEngineLimit.maxEngineCount) {
                    this.$message.error(this.$t('tools.component_EngineConfig_method_selectEngine_message_1', {size: this.userInfo.commonlyEngineLimit.maxEngineCount}));
                    return;
                }
            }
            engine.commonly = !engine.commonly;
        },
        submitBtnEvent() {
            let commonlyEngines = [];
            this.engineList.map(domain => {
                domain.engines.map(item => {
                    if(item.commonly) {
                        commonlyEngines.push({engineKey: item.key, domain: item.domain});
                    }
                });
            });
            if (commonlyEngines.length === 0) {
                this.$message.error(this.$t('tools.component_EngineConfig_method_submitBtnEvent_message_1'));
                return;
            }
            if (commonlyEngines.length > this.userInfo.commonlyEngineLimit.maxEngineCount) {
                this.$message.error(this.$t('tools.component_EngineConfig_method_submitBtnEvent_message_2', {size: this.userInfo.commonlyEngineLimit.maxEngineCount}));
                return;
            }
            this.loading = true;
            let url = myEngineApi.saveTranslatorConfigEngines;
            this.$ajax.post(url, {commonlyEngines}).then(res => {
                if (res.status === 200) {
                    this.$message.success(this.$t('tools.component_EngineConfig_method_submitBtnEvent_message_3'));
                    this.$emit('saved');
                    this.show = false;
                }
            }).finally(() => {
                this.loading = false;
            }); 
        },
    }
}
</script>
<style lang="scss" scoped>
.engine-wrapper {
    padding: 20px 60px;
    .domian-name {
        font-size: 12px;
        color: #999999;
    }
    .engine-list {
        margin-top: 10px;
        margin-bottom: 20px;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        .item {
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid #f8f8f8;
            border-radius: 50px;
            height: 36px;
            background-color: #f8f8f8;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
                border-color: #000;
            }
        }
    }
}
</style>
